var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-table",
        {
          attrs: {
            pagination: _vm.pagination,
            loading: _vm.loading,
            columns: _vm.columns,
            "data-source": _vm.list,
            scroll: { x: 800 },
            rowKey: function(record) {
              return record.id
            },
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange
            }
          },
          on: { change: _vm.changePage },
          scopedSlots: _vm._u([
            {
              key: "size-weight",
              fn: function(row) {
                return _c("div", {}, [
                  _vm._v(
                    " " +
                      _vm._s(row.goods && row.goodsExtraInfo.size) +
                      "/" +
                      _vm._s(row.goodsExtraInfo && row.goodsExtraInfo.weight) +
                      " "
                  )
                ])
              }
            },
            {
              key: "coverUrl",
              fn: function(row) {
                return _c("div", {}, [
                  _c("img", {
                    staticStyle: { width: "50px", height: "50px" },
                    attrs: {
                      src: row.coverUrl
                        ? row.coverUrl
                        : "http://image.douquan.com/static/product-default.png",
                      alt: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.previewImg(row)
                      }
                    }
                  })
                ])
              }
            },
            {
              key: "reviewStatusOrDuiBanStatusSlot",
              fn: function(row) {
                return _c("div", {}, [
                  row.reviewStatus
                    ? _c("div", [
                        row.reviewStatus === 0
                          ? _c("div", { staticClass: "color-red" }, [
                              _vm._v("未确认")
                            ])
                          : _vm._e(),
                        row.reviewStatus === 1
                          ? _c("div", { staticClass: "color-green" }, [
                              _vm._v("已确认")
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  row.duibanStatus
                    ? _c("div", { staticClass: "mt-10" }, [
                        row.duibanStatus === 0
                          ? _c("div", { staticClass: "color-orange" }, [
                              _vm._v(
                                "未对版" +
                                  _vm._s("（" + row.modelVersion + "）")
                              )
                            ])
                          : _vm._e(),
                        row.duibanStatus === 1
                          ? _c("div", { staticClass: "color-blue" }, [
                              _vm._v(
                                "已对版" +
                                  _vm._s("（" + row.modelVersion + "）")
                              )
                            ])
                          : _vm._e(),
                        row.duibanStatus === 5
                          ? _c("div", { staticClass: "color-blue" }, [
                              _vm._v(
                                "已对版-不准" +
                                  _vm._s("（" + row.modelVersion + "）")
                              )
                            ])
                          : _vm._e(),
                        row.duibanStatus === 9
                          ? _c("div", { staticClass: "color-blue" }, [
                              _vm._v(
                                "已对版-无结果" +
                                  _vm._s("（" + row.modelVersion + "）")
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  row.versionName
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-10 color-black",
                          class: row.duibanId ? "color-orange cur-pot" : "",
                          on: {
                            click: function($event) {
                              return _vm.handleGoToVersionList(row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.versionName))]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mt-10" },
                    [
                      row.duibanStatus
                        ? _c(
                            "a-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.handleSameVersion(row)
                                }
                              }
                            },
                            [_vm._v("同版")]
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          class: row.duibanStatus ? "ml-10" : "",
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleGoToBootVersion(row)
                            }
                          }
                        },
                        [_vm._v("机器对版")]
                      )
                    ],
                    1
                  )
                ])
              }
            },
            {
              key: "setup",
              fn: function(row) {
                return _c(
                  "div",
                  {
                    staticStyle: { display: "flex", "flex-direction": "column" }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "5px", display: "flex" } },
                      [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: "是否确认?",
                              "ok-text": "Yes",
                              "cancel-text": "No"
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.handleConfirmPriceQuery(row.id)
                              }
                            }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { size: "small", type: "primary" }
                              },
                              [_vm._v("确认")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.editItem(row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        false
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.appreciation(row)
                                  }
                                }
                              },
                              [_vm._v("鉴赏")]
                            )
                          : _vm._e(),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: "Are you sure delete this item?",
                              "ok-text": "Yes",
                              "cancel-text": "No"
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.remove(row.id)
                              }
                            }
                          },
                          [
                            _c(
                              "a-button",
                              { attrs: { size: "small", type: "danger" } },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              }
            }
          ])
        },
        [
          _c("template", { slot: "price" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("div", { staticStyle: { width: "50px" } }, [
                  _vm._v("成交价")
                ])
              ]
            )
          ]),
          _c("template", { slot: "time" }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("div", { staticStyle: { width: "80px" } }, [
                  _vm._v("成交时间")
                ])
              ]
            )
          ])
        ],
        2
      ),
      _c("AddAppreciation", {
        ref: "add-appreciation",
        attrs: { detail: _vm.detail },
        on: { success: _vm.appreciationSuccess }
      }),
      _c("ImgVersionPopup", {
        ref: "imgVersionPopupEl",
        on: { versionSuccess: _vm.appreciationSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }